<template>
  <div class="flex-row-fluid col-lg-9">
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label text-dark">Extrato</span>
            <span class="text-muted mt-2 font-weight-bold font-size-sm">
              Verique abaixo todas suas operações realizadas.
            </span>
          </h3>
        </div>
      </div>
      <div class="card-body">
        <TableExtractReal />
      </div>
    </div>
  </div>
</template>

<script>
import TableExtractReal from '@/shared/components/extract/TableExtractReal';

export default {
  components: {
    TableExtractReal
  },
  mounted() {
    this.$store.dispatch('SET_BREADCRUMB', [{ title: 'Carteiras' }, { title: 'Carteira Reais' }, { title: 'Extrato' }]);
  }
};
</script>

<style></style>
